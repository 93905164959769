<style scoped>
.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}
</style>

<template>
  <div>
    <!-- Today's Statistics -->
    <div>
      <b-row>
        <!-- Transaction -->
        <b-col
          v-if="transactionCountData.length"
          cols="4"
          md="3"
        >
          <LineChart
            icon="ListIcon"
            color="primary"
            :statistic="$options.filters.number(transactionCountData[transactionCountData.length - 1])"
            :diff-ratio="transactionCountData[transactionCountData.length - 1] / transactionCountData[transactionCountData.length - 2] - 1"
            :updated-at="countUpdatedAt"
            statistic-title="Today's transaction"
            :chart-data="transactionData"
            :chart-options="transactionOptions"
            :loading="loadingStatisticCount"
          />
        </b-col>
        <b-col
          v-else-if="loadingStatisticCount"
          cols="6"
          md="3"
        >
          <b-skeleton height="233px" />
        </b-col>

        <!-- Registration -->
        <b-col
          v-if="registrationCountData.length"
          cols="4"
          md="3"
        >
          <LineChart
            icon="UserIcon"
            color="warning"
            :statistic="$options.filters.number(registrationCountData[registrationCountData.length - 1])"
            :diff-ratio="registrationCountData[registrationCountData.length - 1] / registrationCountData[registrationCountData.length - 2] - 1"
            :updated-at="countUpdatedAt"
            statistic-title="Today's registration"
            :chart-data="registrationData"
            :chart-options="registrationOptions"
            :loading="loadingStatisticCount"
          />
        </b-col>
        <b-col
          v-else-if="loadingStatisticCount"
          cols="6"
          md="3"
        >
          <b-skeleton height="233px" />
        </b-col>
      </b-row>
    </div>

    <hr class="mt-n50 mb-2">

    <!-- Creator Leaderboard -->
    <b-row>

      <!-- Creator Extra Info -->
      <b-col
        v-if="topCreatorLeaderboardData.length"
        xl="6"
        md="6"
      >
        <b-card no-body>
          <b-card-header class="pb-0">
            <b-card-title class="d-flex align-items-center">
              Top Creator Board (Today) &nbsp;
              <small>(Last Fetched: {{ valFormattedCurrentTime }})</small>
            </b-card-title>
          </b-card-header>

          <!-- Table Header -->
          <b-list-group
            class="my-1"
            flush
          >
            <b-list-group-item class="font-weight-bold text-uppercase">
              <b-row align-v="center">
                <b-col
                  cols="4"
                  class="text-left"
                >
                  Creator
                </b-col>
                <!-- <b-col
                  cols="2"
                  class="text-left"
                >
                  Day
                </b-col> -->
                <b-col
                  cols="2"
                  class="text-left"
                >
                  Country
                </b-col>
                <b-col
                  cols="2"
                  class="text-left"
                >
                  Cohort
                </b-col>
                <b-col
                  cols="2"
                  class="text-right"
                >
                  Total Revenue
                </b-col>
                <b-col
                  cols="2"
                  class="text-right"
                >
                  Success Tx Count
                </b-col>
              </b-row>
            </b-list-group-item>

            <!-- Data Rows -->
            <b-list-group-item
              v-for="(item, index) in topCreatorLeaderboardData"
              :key="`top-leaderboard-${index}`"
            >
              <b-row align-v="center">
                <b-col
                  cols="4"
                  class="text-left"
                >
                  <TableField
                    type="profile"
                    :value="item.creator"
                    :url="`/user/${item.creator_id}`"
                  />
                </b-col>
                <!-- <b-col
                  cols="2"
                  class="text-left"
                >
                  {{ formatDate(item.day, 'YYYY MMMM, DD') }}
                </b-col> -->
                <b-col
                  cols="2"
                  class="text-left"
                >
                  {{ item.country }}
                </b-col>
                <b-col
                  cols="2"
                  class="text-left"
                >
                  {{ item.cohort }}
                </b-col>
                <b-col
                  cols="2"
                  class="text-right"
                >
                  ${{ item.total_revenue }}
                </b-col>
                <b-col
                  cols="2"
                  class="text-right"
                >
                  {{ item.success_tx_count }}
                </b-col>
              </b-row>
            </b-list-group-item>
          </b-list-group>
        </b-card>
      </b-col>
      <b-col
        v-if="topCreatorLeaderboardDataLast.length"
        xl="6"
        md="6"
      >
        <b-card no-body>
          <b-card-header class="pb-0">
            <b-card-title class="d-flex align-items-center">
              Top Creator Board (Yesterday) &nbsp;
              <small>(Last Fetched: {{ valFormattedCurrentTime }})</small>
            </b-card-title>
            <b-dropdown
              no-caret
              right
              :text="daysLimitTopCreatorLeaderBoard.text"
              variant="transparent"
              class="chart-dropdown"
              toggle-class="p-50"
              size="sm"
            >
              <b-dropdown-item
                v-for="(day, index) in lastDaysTop"
                :key="`revenue-services-days-limit-${index}`"
                @click="selectDaysLimitTopCreatorLeaderBoard(day)"
              >
                {{ day.text }}
              </b-dropdown-item>
              <b-dropdown-divider v-if="false" />
              <b-dropdown-item v-if="false">
                Custom
              </b-dropdown-item>
            </b-dropdown>
          </b-card-header>

          <!-- Table Header -->
          <b-list-group
            class="my-1"
            flush
          >
            <b-list-group-item class="font-weight-bold text-uppercase">
              <b-row align-v="center">
                <b-col
                  cols="4"
                  class="text-left"
                >
                  Creator
                </b-col>
                <!-- <b-col
                  cols="2"
                  class="text-left"
                >
                  Day
                </b-col> -->
                <b-col
                  cols="2"
                  class="text-left"
                >
                  Country
                </b-col>
                <b-col
                  cols="2"
                  class="text-left"
                >
                  Cohort
                </b-col>
                <b-col
                  cols="2"
                  class="text-right"
                >
                  Total Revenue
                </b-col>
                <b-col
                  cols="2"
                  class="text-right"
                >
                  Success Tx Count
                </b-col>
              </b-row>
            </b-list-group-item>

            <!-- Data Rows -->
            <b-list-group-item
              v-for="(item, index) in topCreatorLeaderboardDataLast"
              :key="`top-leaderboard-${index}`"
            >
              <b-row align-v="center">
                <b-col
                  cols="4"
                  class="text-left"
                >
                  <TableField
                    type="profile"
                    :value="item.creator"
                    :url="`/user/${item.creator_id}`"
                  />
                </b-col>
                <!-- <b-col
                  cols="2"
                  class="text-left"
                >
                  {{ formatDate(item.day, 'YYYY MMMM, DD') }}
                </b-col> -->
                <b-col
                  cols="2"
                  class="text-left"
                >
                  {{ item.country }}
                </b-col>
                <b-col
                  cols="2"
                  class="text-left"
                >
                  {{ item.cohort }}
                </b-col>
                <b-col
                  cols="2"
                  class="text-right"
                >
                  {{ item.total_revenue }}$
                </b-col>
                <b-col
                  cols="2"
                  class="text-right"
                >
                  {{ item.success_tx_count }}
                </b-col>
              </b-row>
            </b-list-group-item>
          </b-list-group>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import LineChart from '@/layouts/components/Chart/LineChart.vue'
// import StreamReport from '@/layouts/components/Chart/StreamReport.vue'
// import UserInterest from '@/layouts/components/Chart/UserInterest.vue'
// eslint-disable-next-line import/no-cycle
import { kFormatter } from '@core/utils/filter'
import moment from 'moment'
import { mapGetters, mapState } from 'vuex'
// import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

import {
  BRow,
  BCol,
  BSkeleton,
  BCard,
  BCardHeader,
  // BCardBody,
  BCardTitle,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BListGroup,
  BListGroupItem,
  // BSpinner,
} from 'bootstrap-vue'
import TableField from '@/layouts/components/TableField.vue'

export default {
  components: {
    BRow,
    BCol,
    BSkeleton,
    LineChart,
    BCard,
    BCardHeader,
    // BCardBody,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BListGroup,
    BListGroupItem,
    // VueApexCharts,
    TableField,
    // StreamReport,
    // UserInterest,
    // BSpinner,
  },
  data() {
    return {
      abortController: null,
      valFormattedCurrentTime: null,
      daysLimit: 7,
      daysLimitRevenueByServices: {
        value: 7,
        text: 'Last 7 Days',
      },
      daysLimitRevenueByRegions: {
        value: 7,
        text: 'Last 7 Days',
      },
      daysLimitCreatorLeaderBoard: {
        value: 7,
        text: 'Last 7 Days',
      },
      daysLimitTopCreatorLeaderBoard: {
        value: 1,
        text: 'Yesterday',
      },
      lastDays: [
        {
          value: 7,
          text: 'Last 7 Days',
        },
        {
          value: 14,
          text: 'Last 14 Days',
        },
        {
          value: 30,
          text: 'Last 30 Days',
        },
      ],
      lastDaysTop: [
        {
          value: 7,
          text: 'Last 7 Days',
        },
        {
          value: 14,
          text: 'Last 14 Days',
        },
        {
          value: 28,
          text: 'Last 28 Days',
        },
      ],
      seriesRegion: [
        {
          name: 'Philippines',
          data: [50, 40, 30, 70, 40, 60, 50],
        },
        {
          name: 'Thailand',
          data: [100, 120, 90, 170, 130, 160, 130],
        },
        {
          name: 'Malaysia',
          data: [200, 280, 270, 210, 280, 220, 290],
        },
        {
          name: 'Singapore',
          data: [140, 160, 80, 190, 180, 210, 170],
        },
        {
          name: 'Indonesia',
          data: [20, 80, 70, 140, 80, 100, 90],
        },
      ],
    }
  },
  computed: {
    ...mapState('dashboard', [
      'creatorLeaderboardData',
      'topCreatorLeaderboardData',
      'topCreatorLeaderboardDataLast',
      'loadingStatisticCount',
      'loadingStatisticAmount',
      'loadingRevenueServices',
      'loadingRevenueRegions',
      'loadingCreatorLeaderboard',
      'loadingTopCreatorLeaderboard',
      'statisticCountData',
    ]),
    ...mapGetters({
      countUpdatedAt: 'dashboard/countUpdatedAt',
      amountUpdatedAt: 'dashboard/amountUpdatedAt',
      revenueServicesUpdatedAt: 'dashboard/revenueServicesUpdatedAt',
      revenueRegionsUpdatedAt: 'dashboard/revenueRegionsUpdatedAt',

      xAxisCountLabels: 'dashboard/xAxisCountLabels',
      xAxisAmountLabels: 'dashboard/xAxisAmountLabels',
      xAxisRevenueServicesLabels: 'dashboard/xAxisRevenueServicesLabels',
      xAxisRevenueRegionsLabels: 'dashboard/xAxisRevenueRegionsLabels',

      transactionCountData: 'dashboard/transactionCountData',
      registrationCountData: 'dashboard/registrationCountData',
      donationAmountData: 'dashboard/donationAmountData',
      subscriptionAmountData: 'dashboard/subscriptionAmountData',
      revenueServicesData: 'dashboard/revenueServicesData',
      revenueRegionsData: 'dashboard/revenueRegionsData',
    }),
    transactionOptions() {
      return {
        xaxis: {
          type: 'datetime',
          categories: this.xAxisCountLabels.slice(0, this.daysLimit),
        },
        yaxis: {
          show: false,
        },
        tooltip: {
          x: { show: true, format: 'MMM d' },
          y: {
            formatter: value => this.$options.filters.number(value),
          },
        },
      }
    },
    transactionData() {
      return [
        {
          name: 'Transaction',
          data: this.transactionCountData.slice(0, this.daysLimit),
        },
      ]
    },
    registrationOptions() {
      return {
        xaxis: {
          type: 'datetime',
          categories: this.xAxisCountLabels.slice(0, this.daysLimit),
        },
        yaxis: {
          show: false,
        },
        tooltip: {
          x: { show: true, format: 'MMM d' },
          y: {
            formatter: value => this.$options.filters.number(value),
          },
        },
      }
    },
    registrationData() {
      return [
        {
          name: 'Registration',
          data: this.registrationCountData.slice(0, this.daysLimit),
        },
      ]
    },
    donationOptions() {
      return {
        xaxis: {
          type: 'datetime',
          categories: this.xAxisAmountLabels.slice(0, this.daysLimit),
        },
        yaxis: {
          show: false,
        },
        tooltip: {
          x: { show: true, format: 'MMM d' },
          y: {
            formatter: value => `$${this.kFormatter(value)} USD`,
          },
        },
      }
    },
    donationData() {
      return [
        {
          name: 'Donation',
          data: this.donationAmountData.slice(0, this.daysLimit),
        },
      ]
    },
    subscriptionOptions() {
      return {
        xaxis: {
          type: 'datetime',
          categories: this.xAxisAmountLabels.slice(0, this.daysLimit),
        },
        yaxis: {
          show: false,
        },
        tooltip: {
          x: { show: true, format: 'MMM d' },
          y: {
            formatter: value => `$${this.kFormatter(value)} USD`,
          },
        },
      }
    },
    subscriptionData() {
      return [
        {
          name: 'Subscription',
          data: this.subscriptionAmountData.slice(0, this.daysLimit),
        },
      ]
    },
    chartOptionsRevenueByServices() {
      return {
        chart: {
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        markers: {
          strokeWidth: 7,
          strokeOpacity: 1,
          strokeColors: [
            $themeColors.success,
            $themeColors.info,
            $themeColors.primary,
            $themeColors.warning,
            $themeColors.secondary,
            $themeColors.dark,
          ],
          colors: [
            $themeColors.success,
            $themeColors.info,
            $themeColors.primary,
            $themeColors.warning,
            $themeColors.secondary,
            $themeColors.dark,
          ],
        },
        colors: [
          $themeColors.success,
          $themeColors.info,
          $themeColors.primary,
          $themeColors.warning,
          $themeColors.secondary,
          $themeColors.dark,
        ],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
        },
        grid: {
          xaxis: {
            lines: {
              show: false,
            },
          },
        },
        xaxis: {
          categories: this.xAxisRevenueServicesLabels.slice(0, this.daysLimitRevenueByServices.value),
          labels: {
            formatter: value => moment(value).format('MMM D'),
          },
        },
        yaxis: {
          labels: {
            formatter: value => `$${this.kFormatter(value)}`,
          },
        },
        tooltip: {
          x: { show: true },
          y: {
            formatter: value => `$${this.kFormatter(value)}`,
          },
        },
      }
    },
    chartOptionsRevenueByRegion() {
      return {
        chart: {
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        markers: {
          strokeWidth: 7,
          strokeOpacity: 1,
          strokeColors: [
            $themeColors.success,
            $themeColors.secondary,
            $themeColors.warning,
            $themeColors.info,
            $themeColors.primary,
          ],
          colors: [
            $themeColors.success,
            $themeColors.secondary,
            $themeColors.warning,
            $themeColors.info,
            $themeColors.primary,
          ],
        },
        colors: [
          $themeColors.success,
          $themeColors.secondary,
          $themeColors.warning,
          $themeColors.info,
          $themeColors.primary,
        ],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
        },
        grid: {
          xaxis: {
            lines: {
              show: false,
            },
          },
        },
        xaxis: {
          categories: this.xAxisRevenueRegionsLabels.slice(0, this.daysLimitRevenueByRegions.value),
          labels: {
            formatter: value => moment(value).format('MMM D'),
          },
        },
        yaxis: {
          labels: {
            formatter: value => `$${this.kFormatter(value)}`,
          },
        },
        tooltip: {
          x: { show: true },
          y: {
            formatter: value => `$${this.kFormatter(value)}`,
          },
        },
      }
    },
  },
  beforeRouteLeave(_, __, next) {
    this.abortController.abort()
    next()
  },
  mounted() {
    this.abortController = new AbortController()

    // delay load data
    setTimeout(this.loadData, 2000)
  },
  methods: {
    kFormatter,
    loadData() {
      // check is still on the dashboard page
      if (this.$route.name !== 'dashboard') {
        return
      }

      const { signal } = this.abortController

      // get statistic data
      this.$store.dispatch('dashboard/getStatisticCount', {
        signal,
        startedAt: moment().subtract(this.daysLimit, 'days').utc().toISOString(),
        endedAt: moment().utc().toISOString(),
      })

      // this.$store.dispatch('dashboard/getStatisticAmount', {
      //   signal,
      //   startedAt: moment().subtract(this.daysLimit, 'days').utc().toISOString(),
      //   endedAt: moment().utc().toISOString(),
      // })

      // this.loadRevenueByServices()
      // this.loadRevenueByRegions()
      this.loadCreatorLeaderboard()
      this.loadTopcreatorLeaderboard('now')
      this.loadTopcreatorLeaderboard('yesterday')
    },
    loadRevenueByServices() {
      const { signal } = this.abortController
      this.$store.dispatch('dashboard/getRevenueByServices', {
        signal,
        startedAt: moment()
          .subtract(this.daysLimitRevenueByServices.value - 1, 'days')
          .startOf('day')
          .utc()
          .toISOString(),
        endedAt: moment().startOf('day').utc()
          .toISOString(),
      })
    },
    loadRevenueByRegions() {
      const { signal } = this.abortController
      this.$store.dispatch('dashboard/getRevenueByRegions', {
        signal,
        startedAt: moment()
          .subtract(this.daysLimitRevenueByRegions.value - 1, 'days')
          .startOf('day')
          .utc()
          .toISOString(),
        endedAt: moment().startOf('day').utc()
          .toISOString(),
      })
    },
    loadCreatorLeaderboard() {
      const { signal } = this.abortController
      this.$store.dispatch('dashboard/getCreatorLeaderboard', {
        signal,
        startedAt: moment()
          .subtract(this.daysLimitCreatorLeaderBoard.value - 1, 'days')
          .startOf('day')
          .utc()
          .toISOString(),
        endedAt: moment().startOf('day').utc()
          .toISOString(),
      })
    },
    loadTopcreatorLeaderboard(direction) {
      const { signal } = this.abortController
      const params = {
        limit: 20,
      }

      switch (direction) {
        case 'now':
          params.startedAt = moment()
            .startOf('day')
            .format('YYYY-MM-DDTHH:mm:ssZ')
          params.endedAt = moment()
            .endOf('day')
            .format('YYYY-MM-DDTHH:mm:ssZ')

          this.$store.dispatch('dashboard/getTopCreatorLeaderboard', {
            signal,
            ...params,
          })
          break
        case 'yesterday':
          params.startedAt = moment()
            .subtract(1, 'days')
            .startOf('day')
            .format('YYYY-MM-DDTHH:mm:ssZ')
          params.endedAt = moment()
            .subtract(1, 'days')
            .endOf('day')
            .format('YYYY-MM-DDTHH:mm:ssZ')

          this.$store.dispatch('dashboard/getTopCreatorLeaderboardLast', {
            signal,
            ...params,
          })
          break
        default:
          break
      }
      this.valFormattedCurrentTime = moment().format('YYYY-MMM-DD HH:mm:ss')
    },
    selectDaysLimitRevenueByServices(day) {
      this.daysLimitRevenueByServices = day
      this.loadRevenueByServices()
    },
    selectDaysLimitRevenueByRegions(day) {
      this.daysLimitRevenueByRegions = day
      this.loadRevenueByRegions()
    },
    selectDaysLimitCreatorLeaderBoard(day) {
      this.daysLimitCreatorLeaderBoard = day
      this.loadCreatorLeaderboard()
    },
    selectDaysLimitTopCreatorLeaderBoard(day) {
      this.daysLimitTopCreatorLeaderBoard = day
      this.loadTopcreatorLeaderboard('yesterday')

      this.valFormattedCurrentTime = moment().format('YYYY-MMM-DD HH:mm:ss')
    },
    formatDate(input, format) {
      return moment(input).format(format)
    },
  },
}
</script>
